import { Heading, Stack } from '@gr4vy/poutine-react'
import { Summary } from 'shared/components/Summary'
import EventsConnection from 'transactions/components/EventsPanel/EventsConnection'
import { eventDateFormat } from 'transactions/helpers/event-date-format'
import { TransactionEvent } from 'transactions/services'
import styles from './styles.module.scss'

export const ReportTransactionSettled = ({
  event,
}: {
  event: TransactionEvent
}) => (
  <Stack gap={32}>
    <Stack gap={8}>
      <Heading as="h5" level={5}>
        Connection
      </Heading>
      <EventsConnection event={event} />
    </Stack>
    <Stack gap={8} alignItems="flex-start">
      <Heading as="h5" level={5}>
        Details
      </Heading>
      <Summary className={styles.summary} inline>
        <Summary.Key>Unique ID</Summary.Key>
        <Summary.Value fallback={!event.context.uniqueId} monospace>
          {event.context.uniqueId}
        </Summary.Value>
      </Summary>
      <Summary className={styles.summary} inline>
        <Summary.Key>Report ID</Summary.Key>
        <Summary.Value
          fallback={!event.context.paymentServiceReportId}
          monospace
        >
          {event.context.paymentServiceReportId}
        </Summary.Value>
      </Summary>
      <Summary className={styles.summary} inline>
        <Summary.Key>Report File IDs</Summary.Key>
        <Summary.Value
          fallback={!event.context.paymentServiceReportIds}
          monospace
        >
          {event.context.paymentServiceReportIds.join(', ')}
        </Summary.Value>
      </Summary>
      <Summary className={styles.summary} inline>
        <Summary.Key>Transaction ID</Summary.Key>
        <Summary.Value fallback={!event.context.transactionId} monospace>
          {event.context.transactionId}
        </Summary.Value>
      </Summary>
      <Summary className={styles.summary} inline>
        <Summary.Key>Posted Date</Summary.Key>
        <Summary.Value fallback={!event.context.postedAt} monospace>
          {eventDateFormat(event.context['postedAt'])}
        </Summary.Value>
      </Summary>
      <Summary className={styles.summary} inline>
        <Summary.Key>Ingested Date</Summary.Key>
        <Summary.Value fallback={!event.context.ingestedAt} monospace>
          {eventDateFormat(event.context['ingestedAt'])}
        </Summary.Value>
      </Summary>
    </Stack>
  </Stack>
)
